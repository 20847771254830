import i18next from 'i18next';

const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

const USER_ROLES = {
  STANDARD_USER: 'StandardUser',
  SUPER_ADMIN: 'SuperAdmin',
  LOAN_FUND_MANAGER: 'LoanFundManager',
  SUPPORTER_MANAGER: 'SupporterManager',
  ACCOUNTANT: 'Accountant',
};

const SYSTEM_ROLE_LEVEL = {
  SUPER_ADMIN: 1,
  ORGANIZATION_ADMIN: 2,
  STANDARD_USER: 3,
};

const DEFAULT_VALUE_FILTER = {
  PAGE: 0,
  LIMIT: 10,
  SEARCH_QUERY: '',
  WITH_DELETED: false,
};

const ROLE_OPTIONS = [
  { label: 'Supporter Manager', value: USER_ROLES.SUPPORTER_MANAGER },
  { label: 'Loan Fund Manager', value: USER_ROLES.LOAN_FUND_MANAGER },
  { label: 'Accountant', value: USER_ROLES.ACCOUNTANT },
  { label: 'Super Admin', value: USER_ROLES.SUPER_ADMIN },
];

const E_PAYMENT_TYPE = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SEMI_ANNUALLY: 'Semi-Annually',
  ANNUALLY: 'Annually',
  AT_MATURITY: 'At Maturity',
};

const ROUTE_PATHS = {
  FILE_REVIEW_PAGE: '/review/:id',
  LOGIN_PAGE: '/login',
  VERSION_PAGE: '/version',
  LOGOUT_PAGE: '/logout',
  NOT_FOUND_PAGE: '/not-found',
  NOT_AUTHORIZED: '/not-authorized',
  PROFILE_PAGE: '/profile',
  UPDATE_PROFILE: '/update-profile',
  DASHBOARD_PAGE: '/',
  CHANGE_PASSWORD: '/change-password',
  ROLES_PAGE: '/ROLES',
  USERS_PAGE: '/users',
  SUPPORTER_MANAGER_PAGE: '/supporterManager',
  SUPPORTER_MANAGER_PAGE_DETAIL: '/supporterManager/:id',
  PARTNERS_PAGE: '/partners',
  PARTNER_DETAIL: '/partners/:id',
  LOAN_PAGE: '/loans',
  LOAN_DETAIL: `/partners/:partnerId/loans/:id`,
  LOAN_DETAIL_PDF: `/partners/:partnerId/loans/:id/pdf`,
  PAYMENT_PAGE: '/payments',
  REPORTS_PAGE: '/reports',
  INVESTMENT_PAGE: '/investment',
  INVESTMENT_DETAIL: '/supporterManager/:supporterId/investment/:id',
  DONATION_PAGE: '/donation',
  DONATION_DETAIL: '/donation/:id',
  ADDITIONAL_SETTINGS: '/additional-settings',
};

const status = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const PAYMENT_STATUS = {
  PAID: 'Paid',
  PAID_IN_FULL: 'Paid in Full',
  PAID_IN_ADVANCE: 'Paid in Advance',
  PARTIAL: 'Partial',
  UN_PAID: 'Unpaid',
};

const API_STATUS = {
  SUCCESS: 200,
};

const PAGE_COUNT = 15;

const EXPORT_FILE_NAME = {
  ORGANIZATION: 'ListOrganization.csv',
  USER: 'ListUser.csv',
  USER_ORGANIZATION: 'UserOrganization.csv',
  PARTNER: 'ListPartners.csv',
  PARTNER_CONTACT: 'ListPartnersContacts.csv',
  LOAN: 'ListLoans.csv',
  PAYMENT: 'ListPayments.csv',
  PAYMENT_SCHEDULE: 'ListLoanPaymentsSchedule.csv',
  SUPPORTER: 'ListSupporter.csv',
  MONTHLY_SUMMARY_REPORT: {
    PERFORMING: 'ListMonthlySummaryOfPerformingReport.csv',
    NON_PERFORMING: 'ListMonthlySummaryOfNonPerformingReport.csv',
  },
  PORTFOLIO_REPORT: 'List-Portfolio-Report.csv',
  PORTFOLIO_NONE_PERFORMANCE_REPORT: 'List-Portfolio-None-Performance-Report.csv',
  UPCOMING_PAYMENTS_REPORT: 'UpcomingPaymentsReport.csv',
  SUPPORTER_INVESTMENT: 'SupporterInvestment.csv',
  SUPPORTER_DONATION: 'SupporterDonation.csv',
  ALL_SUPPORTER_CONTACT: 'All SupporterContact.csv',
  ALL_DONATION: 'AllDonation.csv',
  INVESTMENT_PAYMENT: 'ListInvestmentPayments.csv',
  ALL_INVESTMENT: 'AllInvestment.csv',
};

const ROLES = ['admin', 'user', 'super-admin'];

const TOKEN_KEY = 'token';

const SEARCH_MAX_LENGTH = 500;

const DELAY_SHOW_TOAST = 2000;

const ACCEPT_IMAGE_TYPE_FILE = '.png, .jpeg, .jpg, .gif';

const BUTTON_COLOR = {
  BLUE: 'bg-blue-600 hover:bg-blue-700',
  RED: 'bg-red-500 hover:bg-red-800',
  GRAY: 'bg-gray-300 hover:bg-gray-500',
  OUTLINE: 'bg-white border border-gray-200 hover:bg-blue-100 hover:border-blue-600',
};

const PAGE_DEFAULT = 0;
const LIMIT_DEFAULT = 1000;

const FORMAT_DATE_AM = 'MM/DD/YY, h:m A';
const FORMAT_DATE_API = 'YYYY-MM-DD';

const FORMAT_MONTH_API = 'YYYY-MM';

const FORMAT_DATE_SHOW_TABLE = 'MMMM D, YYYY';
const FORMAT_DATE_PICKER = 'MMMM d, yyyy';
const FORMAT_MONTH_PICKER = 'MMMM yyyy';

const FORMAT_DATE_INPUT = 'M/dd/yy';

const ACTION_STATUS = {
  DEACTIVE: 'deactive',
  REACTIVE: 'reactive',
};

const INTEREST_DENOMINATOR_OPTIONS = [
  { label: '360', value: 360 },
  { label: '365', value: 365 },
];

const INTEREST_OPTIONS = [
  { label: 'Cash-Out', value: 'Cash-Out' },
  { label: 'Roll-Over', value: 'Roll-Over' },
  { label: 'Donate', value: 'Donate' },
];

const INTEREST_OPTION_TYPE = {
  CASH_OUT: 'Cash-Out',
  ROLL_OVER: 'Roll-Over',
  DONATE: 'Donate',
};

const PAYMENT_STATUS_OPTIONS = [
  { label: i18next.t(PAYMENT_STATUS.PAID_IN_FULL), value: PAYMENT_STATUS.PAID_IN_FULL },
  { label: i18next.t(PAYMENT_STATUS.PAID_IN_ADVANCE), value: PAYMENT_STATUS.PAID_IN_ADVANCE },
  { label: i18next.t(PAYMENT_STATUS.PARTIAL), value: PAYMENT_STATUS.PARTIAL },
  { label: i18next.t(PAYMENT_STATUS.UN_PAID), value: PAYMENT_STATUS.UN_PAID },
];

const ABILITY_ACTION = {
  manage: 'manage',
};

const PERMISSION = {
  PARTNER: {
    VIEW: 'VIEW_PARTNER',
    VIEW_DETAIL: 'VIEW_PARTNER_DETAIL',
    CREATE: 'ADD_PARTNER',
    UPDATE: 'EDIT_PARTNER',
  },
  LOAN: {
    VIEW_PARTNER_LOANS: 'VIEW_PARTNER_LOANS',
    VIEW_LOAN_DETAIL: 'VIEW_LOAN_DETAIL',
    CREATE: 'ADD_LOAN',
    UPDATE: 'EDIT_LOAN',
  },
  PAYMENT: {
    VIEW_LOAN_PAYMENTS: 'VIEW_LOAN_PAYMENTS',
    VIEW_PAYMENT_DETAIL: 'VIEW_PAYMENT_DETAIL',
    CREATE: 'ADD_PAYMENT',
    UPDATE: 'EDIT_PAYMENT',
    DELETE: 'DELETE_PAYMENT',
  },
  SUPPORTER: {
    VIEW: 'VIEW_SUPPORTER',
    VIEW_DETAIL: 'VIEW_SUPPORTER_DETAILS',
    CREATE: 'ADD_SUPPORTER',
    UPDATE: 'EDIT_SUPPORTER',
    DELETE: 'DELETE_SUPPORTER',
  },
  INVESTMENT: {
    VIEW: 'VIEW_INVESTMENT',
    VIEW_DETAIL: 'VIEW_INVESTMENT_DETAILS',
    CREATE: 'ADD_INVESTMENT',
    UPDATE: 'EDIT_INVESTMENT',
    DELETE: 'DELETE_INVESTMENT',
  },
  DONATION: {
    VIEW: 'VIEW_DONATION',
    VIEW_DETAIL: 'VIEW_DONATION_DETAILS',
    CREATE: 'ADD_DONATION',
    UPDATE: 'EDIT_DONATION',
    DELETE: 'DELETE_DONATION',
  },
  INVESTMENT_PAYMENT: {
    VIEW: 'VIEW_INVESTMENT_PAYMENT',
    VIEW_DETAIL: 'VIEW_INVESTMENT_PAYMENT_DETAILS',
    CREATE: 'ADD_INVESTMENT_PAYMENT',
    UPDATE: 'EDIT_INVESTMENT_PAYMENT',
    DELETE: 'DELETE_INVESTMENT_PAYMENT',
  },
};

const IMAGE_TYPE_REGEX = /\.(jpg|jpeg|png|gif)$/i;

const OUTLOOK_TYPE = {
  Positive: 'Positive',
  Stable: 'Stable',
  Negative: 'Negative',
};

const INTERNAL_RATING = {
  A: 100,
  'B+': 94.9,
  'B-': 89.9,
  'C+': 84.9,
  'C-': 79.9,
  D: 74.9,
  F: 64.9,
};

const SUPPORTER_TYPE_OPTIONS = [
  { label: i18next.t('Investor'), value: 'Investor' },
  { label: i18next.t('Donor'), value: 'Donor' },
  { label: i18next.t('Contact'), value: 'Contact' },
];
const SUPPORTER_PREFERRED_COMMUNICATION_METHOD_OPTIONS = [
  { label: i18next.t('Email'), value: 'Email' },
  { label: i18next.t('Mail'), value: 'Mail' },
  { label: i18next.t('Mail and Email'), value: 'MailAndEmail' },
  { label: i18next.t('No Solicitation'), value: 'NoSolicitation' },
];

const SUPPORTER_PREFERRED_PAYMENT_METHOD_OPTIONS = [
  { label: 'Check', value: 'Check' },
  { label: 'ACH', value: 'ACH' },
];
const FUND_ALLOCATION = {
  C4C1: 'C4C1',
  C4C2: 'C4C2',
};

const BUSINESS_SECTOR = {
  MICROFINANCE: 'Microfinance',
  VALUE_CHAIN: 'Value Chain',
};

const DONATION_CATEGORY = {
  INVESTOR_INTEREST: 'Investor Interest',
  INVESTOR_PRINCIPAL: 'Investor Principal',
  COMMUNITY_SHARES: 'Community Shares',
  MAILING: 'Mailing',
  ONLINE: 'Online',
  DESIGNATED: 'Designated',
  UNSOLICITED: 'Unsolicited',
  REFERRAL: 'Referral',
  GRANTS: 'Grants',
  OTHER: 'Other',
};

const INVESTMENT_PAYMENT_STATUS = {
  Paid: 'Paid',
  Unpaid: 'Unpaid',
};

const SEARCH_PARTNER_VALUE_KEY = 'searchPartnerValue';

const TYPE_INPUT = {
  CURRENCY: 'currency',
  TEXT: 'text',
  SELECT: 'select',
};

const ENTITY_TYPE = {
  Partner: 'Partner',
  Supporter: 'Supporter',
};

const PREFIX_TITLES = {
  Mr: 'Mr',
  Ms: 'Ms',
  Mrs: 'Mrs',
  Dr: 'Dr',
  Other: 'Other',
};

const SUPPORTER_PREFERRED_PAYMENT_METHOD = { CHECK: 'Check', ACH: 'ACH' };

const NOTE_ENTITY_TYPES = {
  CONTACT: 'Contact',
  PARTNER: 'Partner',
  LOAN: 'Loan',
  PAYMENT: 'Payment',
  SUPPORTER: 'Supporter',
  INVESTMENT: 'Investment',
  DONATION: 'Donation',
  INVESTMENT_PAYMENT: 'investmentPayment',
};

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const FORMAT_DATE_API_REGEX = /^\d{4}-\d{2}-\d{2}$/;

export {
  HTTP_METHODS,
  USER_ROLES,
  ROLE_OPTIONS,
  ROUTE_PATHS,
  status,
  ROLES,
  PAGE_COUNT,
  API_STATUS,
  EXPORT_FILE_NAME,
  TOKEN_KEY,
  SEARCH_MAX_LENGTH,
  DELAY_SHOW_TOAST,
  ACCEPT_IMAGE_TYPE_FILE,
  BUTTON_COLOR,
  PAGE_DEFAULT,
  LIMIT_DEFAULT,
  SYSTEM_ROLE_LEVEL,
  ACTION_STATUS,
  FORMAT_DATE_AM,
  FORMAT_DATE_API,
  FORMAT_DATE_SHOW_TABLE,
  E_PAYMENT_TYPE,
  PAYMENT_STATUS,
  FORMAT_DATE_PICKER,
  FORMAT_DATE_INPUT,
  INTEREST_DENOMINATOR_OPTIONS,
  INTEREST_OPTIONS,
  INTEREST_OPTION_TYPE,
  PAYMENT_STATUS_OPTIONS,
  ABILITY_ACTION,
  PERMISSION,
  DEFAULT_VALUE_FILTER,
  IMAGE_TYPE_REGEX,
  OUTLOOK_TYPE,
  INTERNAL_RATING,
  SUPPORTER_TYPE_OPTIONS,
  FORMAT_MONTH_PICKER,
  SUPPORTER_PREFERRED_COMMUNICATION_METHOD_OPTIONS,
  SUPPORTER_PREFERRED_PAYMENT_METHOD_OPTIONS,
  FUND_ALLOCATION,
  DONATION_CATEGORY,
  SEARCH_PARTNER_VALUE_KEY,
  INVESTMENT_PAYMENT_STATUS,
  TYPE_INPUT,
  ENTITY_TYPE,
  PREFIX_TITLES,
  SUPPORTER_PREFERRED_PAYMENT_METHOD,
  BUSINESS_SECTOR,
  NOTE_ENTITY_TYPES,
  EMAIL_REGEX,
  FORMAT_MONTH_API,
  FORMAT_DATE_API_REGEX,
};
