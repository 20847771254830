import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCalendar, HiOutlineCash, HiOutlineCurrencyDollar } from 'react-icons/hi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getLoanById } from 'api/loanFundManager/loanApi';
import CardIndexGroup from 'components/common/CardIndexGroup';
import moment from 'moment';
import { FORMAT_DATE_AM, FORMAT_DATE_SHOW_TABLE } from 'utils/constants';
import { LoanType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import PaymentsPagePdf from '../../payments/paymentsPdf';

import './loanDetailPdf.scss';

const LoanDetailPdf = () => {
  const params = useParams();
  const loanId = params.id;
  const [selectedLoan, setSelectedLoan] = useState<LoanType>();
  const [t] = useTranslation()

  const { refetch: fetchLoanById, isFetching } = useQuery(['loan'], () => getLoanById(loanId as string), {
    onSuccess: ({ data }) => {
      setSelectedLoan(data);
    },
    onError: () => {
      setSelectedLoan(undefined);
    },
    staleTime: Infinity,
  });


  const cardIndexData = [
    {
      bgColor: 'bg-indigo-50',
      bgIconColor: 'bg-indigo-500',
      textIconColor: 'text-white',
      label: t('adminFee'),
      value: selectedLoan?.adminFee ? `${getNumberValueFromApi(selectedLoan?.adminFee as number).toFixed(2)} %` : '',
      icon: HiOutlineCurrencyDollar,
    },
    {
      bgColor: 'bg-red-50',
      bgIconColor: 'bg-red-500',
      textIconColor: 'text-white',
      label: t('interestRate'),
      value: selectedLoan?.interestRate ? `${getNumberValueFromApi(selectedLoan?.interestRate as number).toFixed(2)} %` : '',
      icon: HiOutlineCurrencyDollar,
    },
    {
      bgColor: 'bg-gray-100',
      bgIconColor: 'bg-gray-400',
      textIconColor: 'text-white',
      label: t('interestDenominator'),
      value: selectedLoan?.interestDenominator ? `${selectedLoan?.interestDenominator as number} ${t('days')}` : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-green-100',
      bgIconColor: 'bg-green-500',
      textIconColor: 'text-white',
      label: t('term'),
      value: `${selectedLoan?.term} ${t('months')}`,
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-yellow-50',
      bgIconColor: 'bg-yellow-400',
      textIconColor: 'text-white',
      label: t('amount'),
      value: convertNumberToCurrency(getNumberValueFromApi(selectedLoan?.amount as number)) as string,
      icon: HiOutlineCash,
    },
    {
      bgColor: 'bg-orange-100',
      bgIconColor: 'bg-orange-500',
      textIconColor: 'text-white',
      label: t('payInterest'),
      value: selectedLoan?.payInterest ? (selectedLoan?.payInterest as string) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-teal-100',
      bgIconColor: 'bg-teal-500',
      textIconColor: 'text-white',
      label: t('payPrincipal'),
      value: selectedLoan?.payPrincipal ? (selectedLoan?.payPrincipal as string) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-indigo-100',
      bgIconColor: 'bg-indigo-500',
      textIconColor: 'text-white',
      label: t('disbursementDate'),
      value: selectedLoan?.disbursementDate ? moment.utc(selectedLoan?.disbursementDate).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-purple-100',
      bgIconColor: 'bg-purple-500',
      textIconColor: 'text-white',
      label: t('firstPaymentDate'),
      value:
        selectedLoan?.firstPrincipalPayment && selectedLoan?.firstInterestPayment
          ? moment
            .utc(
              moment(selectedLoan?.firstPrincipalPayment).toDate() < moment(selectedLoan?.firstInterestPayment).toDate()
                ? selectedLoan?.firstPrincipalPayment
                : selectedLoan?.firstInterestPayment,
            )
            .format(FORMAT_DATE_SHOW_TABLE)
          : '',
      icon: HiOutlineCalendar,
    },
    {
      bgColor: 'bg-pink-100',
      bgIconColor: 'bg-pink-500',
      textIconColor: 'text-white',
      label: t('maturity'),
      value: selectedLoan?.maturity ? moment.utc(selectedLoan?.maturity as string).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineCalendar,
    },
  ];

  return (
    <div className=" w-full pdf-content">
      <div className="pb-5 flex justify-center w-full">
        <div className="font-semibold">{selectedLoan?.name}</div>
      </div>
      <div className="loan-detail__top w-full">
        <CardIndexGroup cardIndexData={cardIndexData} className="grid grid-cols-5 gap-4 mb-3" />
      </div>
      <div className="loan-detail__bottom h-full w-full mb-4">
        <PaymentsPagePdf fetchLoanById={fetchLoanById} selectedLoan={selectedLoan} isFetchingLoan={isFetching} />
      </div>
    </div>
  );
};

export default LoanDetailPdf;
