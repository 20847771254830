import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddUpdateLoanType, updateLoanById } from 'api/loanFundManager/loanApi';
import GroupButton from 'components/button/groupButton';
import TextAreaForm from 'components/form/textForm/textAreaForm';
import TextForm from 'components/form/textForm/textForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import { LoanType } from 'utils/proptypes';
import {
  messageErrors,
  setTextValue,
} from 'utils/utils';
import * as Yup from 'yup';

import './addLoanModal.scss';
import '../../../styles/common/multiSelect.scss';

type EditInfoLoanModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  selectedLoan?: LoanType;
  refetchApi: Function;
  fetchLoans?: Function;
};

type EditInfoLoanFormTypes = {
  name: string;
  collateral: string;
};

const EditInfoLoanModal = (props: EditInfoLoanModalTypes) => {
  const { openModal = false, setOpenModal, selectedLoan, refetchApi, fetchLoans } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const params = useParams();
  const queryClient = useQueryClient();
  const partnerId =  params.partnerId;
  const ValidateSchema = Yup.object().shape({
    name: Yup.string().min(0, t('loanPage.nameLengthMin')).max(100, t('loanPage.nameLengthMax')).nullable().required(t('loanPage.requiredField')),
    collateral: Yup.string().min(0, t('loanPage.collateralLengthMin')).max(4096, t('loanPage.collateralLengthMax')).nullable(),
  });

  const initialValues: any = useMemo(() => {
    return {
        name: selectedLoan?.name ? selectedLoan?.name : '',
        collateral: selectedLoan?.collateral ? selectedLoan?.collateral : '',
      };
  }, [selectedLoan]);

  const handleEditInfoLoan = async (loanPayload: AddUpdateLoanType) => {
      await updateLoanById(selectedLoan?.id as string, loanPayload);
  };

  const mutation = useMutation('edit-info-loan', {
    mutationFn: handleEditInfoLoan,
  });

  const onSubmit = (data: EditInfoLoanFormTypes,
    action: {
      [key: string]: any;
    },) => {
    handleSubmit(data, action)
  }
  const handleSubmit = (
    data: EditInfoLoanFormTypes,
    action: {
      [key: string]: any;
    },
  ) => {
    const successCallback = async (message: string) => {
      setOpenModal(!openModal);
      toast.success(message);
      action.resetForm();
      refetchApi();
      if (typeof fetchLoans === 'function') fetchLoans();
      await queryClient.invalidateQueries('paymentsSchedule');
    };
    const tempData = {
      partnerId,
      name: data.name,
      collateral: data.collateral ? data.collateral : null,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...(tempData as any),
        },
        {
          onSuccess: async () => {
            const message: string = t('loanPage.editSuccessMessage');
            await successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<EditInfoLoanFormTypes>) => {
    setOpenModal(!openModal);
    props.resetForm();
    props.setErrors({});
  };

  useEffect(() => {
    if (openModal) {
      setIsResetForm(!isResetForm);
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setIsResetForm]);

  return (
    <Modal
      id="edit-info-loan-modal"
      show={openModal}
      size="4xl"
      onClose={() => {
        setOpenModal(false);
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{t('editLoanInfo')}</Modal.Header>
      <Modal.Body>
        <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues} validationSchema={ValidateSchema} validateOnChange={true}>
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="form-flex-col flex justify-between items-start space-x-2">
                    <TextForm
                      id="name"
                      name="name"
                      label={t('name')}
                      placeholder={t('name')}
                      propsFormik={props}
                      isRequired={true}
                      setCurrentValue={async (
                        text: {
                          name?: string;
                          value?: string;
                        },
                        props: FormikProps<EditInfoLoanFormTypes>,
                      ) => {
                        await setTextValue<EditInfoLoanFormTypes>({ text, props });
                      }}
                    />
                  </div>
                  <TextAreaForm id="collateral" name="collateral" placeholder={t('collateral')} propsFormik={props} />
                  <GroupButton
                    className="w-full gap-2 justify-center pb-2"
                    buttons={[
                      {
                        type: 'button',
                        text: t('modal.cancel'),
                        classType: 'white',
                        action: () => closeModalHandler(props),
                      },
                      {
                        type: 'submit',
                        text: t('modal.save'),
                        classType: 'blue',
                      },
                    ]}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>

    </Modal>
  );
};

export default EditInfoLoanModal;
