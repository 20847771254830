import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import { Label } from 'flowbite-react';
import { ErrorMessage, FormikProps } from 'formik';

type styleUnitType = 'prefix' | 'suffix';

type CurrencyFormType = {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  setCurrentValue: Function;
  propsFormik: FormikProps<any>;
  isDisabled?: boolean;
  isEdit?: boolean;
  isRequired?: boolean;
  unit?: string;
  styleUnit?: styleUnitType;
  decimalLimit?: number;
  maxLength?: number;
  dataTestId?: string;
};

const CurrencyForm = ({
  name,
  placeholder,
  setCurrentValue,
  propsFormik,
  isDisabled = false,
  isRequired = false,
  unit = '$',
  decimalLimit = 0,
  maxLength = 15,
  dataTestId,
}: CurrencyFormType) => {
  const borderError = 'border-red-500 focus:border-red-500 dark:border-red-600 dark:focus:border-red-500 focus:ring-red-500 dark:focus:ring-red-500';
  const borderSuccess = 'border-gray-200 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500';
  return (
    <div className="currency-form relative flex flex-col w-full" data-testid={dataTestId}>
      <div className="w-full flex justify-center items-center">
        <NumericFormat
          name={name}
          value={propsFormik?.values[name] ? propsFormik?.values[name] : ''}
          onValueChange={values => {
            setCurrentValue({ value: values.floatValue, name }, propsFormik);
          }}
          className={classNames(
            `${
              propsFormik.errors[name] && propsFormik.touched[name] ? borderError : borderSuccess
            } border block w-full disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 p-4 text-sm rounded`,
            { haveValue: propsFormik?.values[name] },
          )}
          disabled={isDisabled}
          maxLength={maxLength}
          thousandSeparator=","
          decimalScale={decimalLimit}
          fixedDecimalScale={true}
          isAllowed={values => {
            const { floatValue, formattedValue } = values;
            return (floatValue as number) >= -1000000000 || formattedValue === '';
          }}
        />
        {unit && <span className="bg-gray-100 currency-form__unit absolute block px-2.5 rounded-r text-sm font-normal leading-none">{unit}</span>}
        <div
          className={classNames('currency-form__label absolute block bg-white', {
            disabled: isDisabled,
            haveValue: propsFormik?.values[name],
          })}
        >
          <Label value={placeholder} />
          {isRequired && !isDisabled && <span className="text-red-500 text-sm"> *</span>}
        </div>
      </div>
      {propsFormik.errors[name] && propsFormik.touched[name] && <ErrorMessage className="text-red-500 text-xs mt-1" name={name} component="p" />}
    </div>
  );
};

export default CurrencyForm;
